import { useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Divider, FormControlLabel, Typography } from "@mui/material";
import CheckBox from "components/CheckBox";

const CheckboxLine = ({
  checked,
  disabled,
  divider,
  inputProps,
  label,
  labelProps,
  message,
  name,
  onChange,
  onChangeDisabledAttempt,
  showMessage,
  value,
}) => {
  const id = `${name || label}-${value}`;

  const handleChangeDisabledAttempt = useCallback(
    () => disabled && onChangeDisabledAttempt(),
    [disabled, onChangeDisabledAttempt],
  );

  return (
    <>
      {divider && <Divider sx={{ height: 0, my: 1 }} />}
      <FormControlLabel
        control={(
          <CheckBox
            checked={checked}
            disabled={disabled}
            id={id}
            inputProps={inputProps}
            name={name}
            onChange={onChange}
            value={value}
          />
        )}
        disabled={disabled}
        label={(
          <Typography
            {...labelProps}
            component="div"
            sx={{
              alignItems: "center",
              display: "flex",
              fontWeight: "normal",
              justifyContent: "left",
              width: "100%",
            }}
          >
            {label}
          </Typography>
        )}
        onClick={handleChangeDisabledAttempt}
        sx={{
          alignItems: "flex-start",
          display: "flex",
          fontWeight: "normal",
          justifyContent: "left",
          width: "100%",

          "&.Mui-disabled": {
            opacity: 0.6,
          },
        }}
      />
      {renderNotificationMessage(showMessage, message)}
    </>
  );
};

const renderNotificationMessage = (showMessage, message) => (
  message && showMessage && (
    <Box
      sx={{
        color: "var(--primary-gray-color)",
        fontSize: "0.9em",
        fontWeight: 500,
        padding: "0.694em 0.694em * 2 0",
        textAlign: "center",
      }}
    >
      {message}
    </Box>
  )
);

CheckboxLine.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  label: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  labelProps: PropTypes.object,
  message: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onChangeDisabledAttempt: PropTypes.func,
  showMessage: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

CheckboxLine.defaultProps = {
  checked: undefined,
  className: undefined,
  disabled: undefined,
  divider: false,
  inputProps: {},
  label: undefined,
  labelProps: {},
  message: "",
  name: undefined,
  onChange: () => {},
  onChangeDisabledAttempt: () => {},
  showMessage: false,
};

export default CheckboxLine;
