import PropTypes from "prop-types";
import { CSSTransitionGroup } from "react-transition-group";
import usePrevious from "hooks/usePrevious";
import Modal from "./index";
import styles from "./styles.module.scss";

const ModalCarousel = ({ modals, currentItem }) => {
  const direction = usePrevious(currentItem) > currentItem ? "Left" : "Right";

  return currentItem !== null && (
    <CSSTransitionGroup
      className="modal"
      transitionEnterTimeout={1500}
      transitionLeaveTimeout={1500}
      transitionName={{
        enter: styles[`modalCarouselSlide${direction}Enter`],
        leave: styles[`modalCarouselSlide${direction}Leave`],
        enterActive: styles[`modalCarouselSlide${direction}EnterActive`],
      }}
    >
      {modals[currentItem]}
    </CSSTransitionGroup>
  );
};

ModalCarousel.propTypes = {
  modals: PropTypes.arrayOf(Modal).isRequired,
  currentItem: PropTypes.number,
};

ModalCarousel.defaultProps = {
  currentItem: null,
};

export default ModalCarousel;
