import { useState } from "react";
import { trackEvent, eventNames } from "utils/EventsTracking";
import Link from "@mui/material/Link";
import Modal from "components/Modal";
import initTranslationsHelper from "utils/initTranslationsHelper";
import styles from "./styles.module.scss";

const translate = initTranslationsHelper("universal.iframe_modal");

const IframeModal = ({ modalDisplayText, modalUrl, offerId }) => {
  const [iframeModalOpen, setIframeModalOpen] = useState(false);
  const handleClick = () => {
    setIframeModalOpen(true);
    trackEvent(eventNames.EPS_OFFER_IFRAME_MODAL_OPENED, { offer_id: offerId });
  };

  return (
    <>
      <Link
        className={styles.blockLink}
        component="button"
        onClick={handleClick}
        variant="body2"
      >
        {modalDisplayText}
      </Link>

      <Modal
        doneButtonText={translate("done")}
        onDoneButtonClick={() => setIframeModalOpen(false)}
        open={iframeModalOpen}
        wide
      >
        <div className={styles.iframeContainer}>
          <div className={styles.iframeContainerRow}>
            <iframe className={styles.iframe} name="IframeModal" src={modalUrl} title="IframeModal" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IframeModal;
