import { Typography, Link } from "@mui/material";
import Modal from "components/Modal";
import useTranslation from "hooks/useTranslation";
import AgreementText from "./AgreementText";

const AgreementModal = ({
  epionAuthModalProperties: {
    epionAuthStandaloneUrl,
    forCheckIn,
    version,
  },
  open,
  setOpen,
}) => {
  const t = useTranslation("universal.epion_authorization.agreement");

  return (
    <Modal
      doneButtonText={t("done")}
      onDoneButtonClick={() => setOpen(false)}
      open={open}
    >
      <Typography color="secondaryColor">
        <p style={{ textAlign: "left" }}>
          {t("link_info")}
          {forCheckIn ? epionAuthStandaloneUrl : (
            <Link
              href={epionAuthStandaloneUrl}
              rel="noreferrer"
              target="_blank"
            >
              {epionAuthStandaloneUrl}
            </Link>
          )}
        </p>
        <Typography
          component="h1"
          mb={1}
          textAlign="center"
          variant="h6"
        >
          {t("epion_authorization")}
        </Typography>
        <AgreementText version={version} />
      </Typography>
    </Modal>
  );
};

export default AgreementModal;
