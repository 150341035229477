import { useFormik } from "formik";
import forEach from "lodash/forEach";
import components from "./components";
import getValuesFromSchema from "./getValuesFromSchema";
import getUpdatedSchemaFromValues from "./getUpdatedSchemaFromValues";
import getErrors from "./getErrors";

function useFormState({ schema, onSubmit }) {
  const initialValues = getValuesFromSchema(schema, components);
  const form = useFormik({
    initialValues,
    initialErrors: getErrors(
      schema,
      components,
      initialValues,
    ),
    validate: (valuesToValidate) => getErrors(
      schema,
      components,
      valuesToValidate,
    ),
    onSubmit: (validValues) => {
      const updatedSchema = getUpdatedSchemaFromValues(
        schema,
        components,
        validValues,
      );
      onSubmit({ updatedSchema, values: validValues });
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isSubmitting,
    isValidating,
  } = form;

  if (
    wasSubmittedWithErrors({
      isSubmitting,
      isValidating,
      errors,
    })
  ) {
    const firstError = document.querySelector(".control-error");
    if (firstError) {
      firstError.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  return {
    currentSchema: schema,
    values,
    errors: errorsForControlsThatDidNotReceiveFocus(errors, touched),
    handleSubmit,
    handleControlChange: setFieldValue,
    handleControlBlur: setFieldTouched,
    isSubmitting,
  };
}

export default useFormState;

function errorsForControlsThatDidNotReceiveFocus(errors, touched) {
  const errorsOfNotTouched = {};

  forEach(errors, (error, name) => {
    if (touched[name]) {
      errorsOfNotTouched[name] = error;
    }
  });

  return errorsOfNotTouched;
}

const wasSubmittedWithErrors = ({ isSubmitting, isValidating, errors }) => {
  const errorKeys = Object.keys(errors);
  return isSubmitting && !isValidating && errorKeys.length;
};
