import PropTypes from "prop-types";
import { FormGroup } from "@mui/material";
import CheckboxLine from "components/CheckboxLine";
import { isClickedValueWithMessage } from "./customPropTypes";

const CheckBoxList = ({ clickedValue, message, onChange, onChangeDisabledOptionAttempt, options }) => (
  <FormGroup sx={{ gap: '10px' }}>
    {options.map((option) => (
      <CheckboxLine
        checked={option.checked}
        disabled={option.disabled}
        divider={option.divider}
        key={option.value}
        label={option.label}
        message={message}
        name={option.name}
        onChange={onChange}
        onChangeDisabledAttempt={() => onChangeDisabledOptionAttempt(option)}
        showMessage={clickedValue === option.value}
        value={option.value}
      />
    ))}
  </FormGroup>
);

CheckBoxList.propTypes = {
  clickedValue: isClickedValueWithMessage,
  message: PropTypes.string,
  onChange: PropTypes.func,
  onChangeDisabledOptionAttempt: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
      value: PropTypes.string.isRequired,
      name: PropTypes.string,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
      className: PropTypes.string,
    }),
  ),
};

CheckBoxList.defaultProps = {
  clickedValue: undefined,
  message: undefined,
  onChange: () => {},
  onChangeDisabledOptionAttempt: () => {},
  options: [],
};

export default CheckBoxList;
