import PropTypes from "prop-types";
import CheckBoxList from "components/CheckBoxList";
import FormField from "components/FormField";

const CheckboxGroup = ({
 disabled, error, label, leftAlign, onChange, options, required,
}) => {
  const checkboxListOptions = options.map((option) => ({
    disabled,
    ...option,
  }));

  return (
    <FormField
      disabled={disabled}
      error={error}
      focused={false}
      fullWidth
      label={label}
      required={required}
    >
      <CheckBoxList leftAlign={leftAlign} onChange={onChange} options={checkboxListOptions} />
    </FormField>
  );
};

CheckboxGroup.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
      value: PropTypes.string.isRequired,
      name: PropTypes.string,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
      className: PropTypes.string,
    }),
  ),
  leftAlign: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxGroup.defaultProps = {
  label: undefined,
  error: undefined,
  disabled: undefined,
  required: undefined,
  options: [],
  onChange: () => {},
  leftAlign: false,
};

export default CheckboxGroup;
