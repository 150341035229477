import CheckboxGroupControlBase from "previsit/CustomForm/Components/CheckboxGroupControl";

const CheckboxGroupControl = (props) => (
  <CheckboxGroupControlBase {...props} leftAlign />
);

CheckboxGroupControl.eligibleForSubmission = true;

CheckboxGroupControl.getValueFromSchema =
  CheckboxGroupControlBase.getValueFromSchema;

CheckboxGroupControl.validate = CheckboxGroupControlBase.validate;

CheckboxGroupControl.getUpdatedSchemaFromValue =
  CheckboxGroupControlBase.getUpdatedSchemaFromValue;

export default CheckboxGroupControl;
