import { useState, useEffect, useRef } from "react";
import Quill from "quill";
import Log from "utils/logging";
import { basePropTypes } from "../propTypes";

const ConsentTextControl = ({ customFormVariables, schema }) => {
  const { value: encodedValue } = schema;
  const [html, setHtml] = useState("");

  const containerRef = useRef();

  useEffect(() => {
    const value = encodedValue ? decodeValue(encodedValue, customFormVariables) : "";
    const transientDOMNode = document.createElement("div");
    const editor = new Quill(transientDOMNode, {});
    editor.setContents(value);
    setHtml(editor.root.innerHTML);
  }, [customFormVariables, encodedValue]);

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: html }} ref={containerRef} />;
};

ConsentTextControl.eligibleForSubmission = false;

ConsentTextControl.propTypes = basePropTypes;

export default ConsentTextControl;

const decodeValue = (encodedValue, customFormVariables) => {
  try {
    const decodedValue = interpolateCustomFormVariables(
      atob(encodedValue),
      customFormVariables,
    );

    return JSON.parse(
      decodeURIComponent(escape(decodedValue)),
    );
  } catch (e) {
    return JSON.parse(
      interpolateCustomFormVariables(atob(encodedValue), customFormVariables),
    );
  }
};

const interpolateCustomFormVariables = (template, variables = {}) => (
  template.replace(/%{([^}]*)}/g, (_, term) => {
    const termValue = variables[term];
    if (!termValue) {
      Log.error(`ConsentTextControl has missed variable: ${term} for the template: ${template}`);
      return term;
    }

    return termValue;
  })
);
