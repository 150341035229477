import clsx from "clsx";
import styles from "./styles.module.scss";

const ControlContainer = ({ children, withError }) => (
  <div className={clsx(styles.root, { "control-error": withError })}>
    {children}
  </div>
);

export default ControlContainer;
