import { useState } from "react";
import getEnv from "utils/getEnv";
import initTranslationsHelper from "utils/initTranslationsHelper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "utils/importAndInitJQuery";
import "jquery.signaturepad";
import Button from "components/Button";
import FormField from "components/FormField";
import Title from "components/Title";
import Modal from "components/Modal";
import useTranslation from "hooks/useTranslation";
import { eligibleForSubmissionPropTypes } from "../propTypes";
import PainDiagram from "./PainDiagram";
import PainDiagramPreview from "./PainDiagramPreview";

const translate = initTranslationsHelper("shared.validation");
const painDiagramForCustomFormEnabled = getEnv("painDiagramForCustomFormEnabled", false);
const PainDiagramControl = ({
  schema,
  onChange,
  value,
  error,
  disabled,
}) => {
  const {
    label,
    painDiagramType,
    required,
  } = schema;
  const t = useTranslation("universal.pain_diagram");
  const [isPainDiagramModalOpened, setIsPainDiagramModalOpened] = useState(false);

  const handlePainDiagramEdit = () => {
    setIsPainDiagramModalOpened(true);
  };
  const handlePainDiagramClose = () => {
    setIsPainDiagramModalOpened(false);
  };

  if (!painDiagramForCustomFormEnabled) {
    return null;
  }

  return (
    <FormField
      disabled={disabled}
      error={error}
      fullWidth
      label={label}
      required={required}
    >
      {!disabled && (
        <Box sx={{ textAlign: "right" }}>
          <Button onClick={handlePainDiagramEdit} variant="text">
            {t("edit")}
          </Button>
        </Box>
      )}
      <PainDiagramPreview
        painDiagramData={value}
        painDiagramType={painDiagramType}
      />
      {isPainDiagramModalOpened && (
        <Modal
          disableBackdropClick
          disableGutters
          onDoneButtonClick={handlePainDiagramClose}
          open
          wide
        >
          <Title sx={{ textAlign: "center" }}>{label}</Title>
          <Typography sx={{ pb: 2, textAlign: "center" }}>{t("tap_to_draw")}</Typography>
          <Box sx={{ px: 1 }}>
            <PainDiagram
              disabled={disabled}
              onChange={onChange}
              painDiagramData={value}
              painDiagramType={painDiagramType}
            />
          </Box>
        </Modal>
      )}
    </FormField>
  );
};

PainDiagramControl.eligibleForSubmission = true;

PainDiagramControl.getValueFromSchema = (schema) => schema.value || null;

PainDiagramControl.getUpdatedSchemaFromValue = (schema, value) => ({
  ...schema,
  value,
});

PainDiagramControl.validate = (schema, value) => {
  const { required, label } = schema;
  if (required && !value) {
    return translate("error_required", { field: label });
  }
  return undefined;
};

PainDiagramControl.propTypes = eligibleForSubmissionPropTypes;

export default PainDiagramControl;
