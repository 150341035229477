import each from "lodash/each";
import isObject from "lodash/isObject";

/**
 * Converts { typography: { fontFamily: "Arial" } } to
 * { typography-font-family: "Arial" }
 */
const flatObjectAndDashKeys = (object) => transverseAndFlatten(object);

export default flatObjectAndDashKeys;

const transverseAndFlatten = (object, path = [], flatten = {}) => {
  each(object, (value, key) => {
    const currentPath = path.concat(key);

    if (isObject(value)) {
      transverseAndFlatten(value, currentPath, flatten);
    } else {
      const flattenKey = currentPath.map(toDashed).join("-");
      flatten[flattenKey] = value;
    }
  });

  return flatten;
};

const toDashed = (camel) => camel.split(/(?=[A-Z])/).join("-").toLowerCase();
