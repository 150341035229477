import TextField from "components/TextField";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { eligibleForSubmissionPropTypes } from "../propTypes";

const translate = initTranslationsHelper("universal.custom_forms.textfield_control");
const DEFAULT_TEXT_FIELD_MAX_LENGTH = 78;

const TextFieldControl = ({
  schema,
  onChange,
  onBlur,
  value,
  error,
  disabled,
}) => {
  const {
    label,
    name,
    subtype: type,
    placeholder,
    required,
    description,
    maxlength,
  } = schema;
  const maxLength = maxlength === undefined || maxlength > DEFAULT_TEXT_FIELD_MAX_LENGTH
    ? DEFAULT_TEXT_FIELD_MAX_LENGTH
    : maxlength;
  return (
    <TextField
      disabled={disabled}
      error={error}
      fullWidth
      helperText={description}
      inputProps={{
        maxLength,
        onBlur,
      }}
      label={label}
      name={name}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
    />
  );
};

TextFieldControl.eligibleForSubmission = true;

TextFieldControl.getValueFromSchema = (schema) => schema.value || "";

TextFieldControl.getUpdatedSchemaFromValue = (schema, value) => ({
  ...schema,
  value,
});

TextFieldControl.validate = (schema, value) => {
  const { required, maxlength, label } = schema;
  if (required && !value) {
    return translate("error_required", { field: label });
  }
  if (maxlength && value.length > maxlength) {
    return translate("error_maxlength", { maxlength });
  }
  return undefined;
};

TextFieldControl.propTypes = eligibleForSubmissionPropTypes;

export default TextFieldControl;
