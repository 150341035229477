import Select from "components/Select";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { eligibleForSubmissionPropTypes } from "../propTypes";

const translate = initTranslationsHelper("universal.custom_forms.select_control");

const SelectControl = ({
  schema,
  onChange,
  onBlur,
  value,
  error,
  disabled,
}) => {
  const {
    label,
    placeholder,
    values: options,
    required,
    description,
  } = schema;

  const handleOnChange = (e) => onChange(e.target.value);

  return (
    <Select
      disabled={disabled}
      emptyOption={placeholder}
      error={error}
      helperText={description}
      label={label}
      onBlur={onBlur}
      onChange={handleOnChange}
      options={options}
      required={required}
      value={value}
    />
  );
};

SelectControl.eligibleForSubmission = true;

SelectControl.getValueFromSchema = ({ values: options }) => {
  const selectedOption = options.find((option) => option.selected);
  return selectedOption ? selectedOption.value : undefined;
};

SelectControl.getUpdatedSchemaFromValue =
  (schema, value) => ({
    ...schema,
    values: schema.values.map((option) => ({
      ...option,
      selected: option.value === value,
    })),
    value,
  });

SelectControl.validate = (schema, value) => {
  const { required, label } = schema;
  if (required && !value) {
    return translate("error_required", { field: label });
  }
  return undefined;
};

SelectControl.propTypes = eligibleForSubmissionPropTypes;

export default SelectControl;
