import { Box } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import IframeModal from "components/IframeModal";
import CustomFormScreen from "previsit/CustomForm/CustomFormScreen";
import useFormState from "previsit/CustomForm/useFormState";
import EpionAuthorizationModal from "previsit/EpionAuthorization/Modal";
import initTranslationsHelper from "utils/initTranslationsHelper";
import Agreements from "previsit/TermsOfUse/Agreements";
import saveResponse from "./saveResponse";
import epsComponents from "../epsComponents";

const ResponseScreen = ({
  action,
  data: {
    customFormVariables,
    disclaimer,
    epionAuthModalProperties,
    epionTermsStandaloneUrl,
    epionTermsVersion,
    logoAlt,
    logoSrc,
    modalDisplayText,
    modalUrl,
    newTabUrl,
    nextStepPath,
    offerId,
    privacyPolicyStandaloneUrl,
    privacyPolicyVersion,
    schema,
    stepName,
  },
  method,
}) => {
  const onSubmit = () => {
    if (nextStepPath) {
      window.location.href = nextStepPath;
      return;
    }

    if (newTabUrl && optedInToAService(values)) {
      window.open(newTabUrl, `_eps_offer_${offerId}`).focus();
    }

    saveResponse({ action, method, offerId, values });
  };

  const {
    currentSchema,
    errors,
    handleControlBlur,
    handleControlChange,
    handleSubmit,
    isSubmitting,
    values,
  } = useFormState({
    schema,
    onSubmit,
  });

  const showIframeModal = !!modalDisplayText && !!modalUrl;
  const pageNoteContent = (
    <>
      {showIframeModal && (
        <Box mb={1}>
          <IframeModal
            modalDisplayText={modalDisplayText}
            modalUrl={modalUrl}
            offerId={offerId}
          />
        </Box>
      )}
      {disclaimer && <Box>{disclaimer}</Box>}
      <Agreements
        epionTermsStandaloneUrl={epionTermsStandaloneUrl}
        epionTermsVersion={epionTermsVersion}
        privacyPolicyStandaloneUrl={privacyPolicyStandaloneUrl}
        privacyPolicyVersion={privacyPolicyVersion}
      />
    </>
  );

  let infoMessage;
  if (nextStepPath) {
    infoMessage = translate("response_already_submitted");
  } else if (epionAuthModalProperties) {
    infoMessage = <EpionAuthModalAlert epionAuthModalProperties={epionAuthModalProperties} />;
  }

  return (
    <CustomFormScreen
      components={epsComponents}
      customFormVariables={customFormVariables}
      disabled={!!nextStepPath}
      errors={errors}
      infoMessage={infoMessage}
      isSubmitting={isSubmitting}
      logoAlt={logoAlt}
      logoSrc={logoSrc}
      name={stepName}
      onControlBlur={handleControlBlur}
      onControlChange={handleControlChange}
      onSubmit={handleSubmit}
      pageNoteContent={pageNoteContent}
      schema={currentSchema}
      submitButtonText="Continue"
      titleVariant="secondary"
      values={values}
    />
  );
};

const EpionAuthModalAlert = ({ epionAuthModalProperties }) => (
  <Box>
    {translate("authorization_reminder")}
    <EpionAuthorizationModal
      epionAuthModalProperties={epionAuthModalProperties}
      eventTracking={false}
      type="button"
    />
  </Box>
);

const translate = initTranslationsHelper("universal.eps.offer");

const optedInToAService = (values) =>
  values && !isEmpty(values.patient_services) && values.patient_services !== "not_now";

export default ResponseScreen;
