import ControlContainer from "./ControlContainer";
import ConsentTextControl from "./Components/ConsentTextControl";

const Controls = ({
  components,
  controls,
  customFormVariables,
  disabled,
  errors,
  onControlBlur,
  onControlChange,
  values,
}) => (
  <>
    {controls.reduce((renderedComponentsSoFar, control, index) => {
      const { type, name } = control;

      const ControlComponent = components[type];
      if (!ControlComponent) {
        return renderedComponentsSoFar;
      }

      const componentProps = {
        schema: control,
      };
      if (ControlComponent.name === ConsentTextControl.name) {
        componentProps.customFormVariables = customFormVariables;
      }

      if (ControlComponent.eligibleForSubmission) {
        componentProps.value = values[name];
        componentProps.onChange = (value) => onControlChange(name, value);
        componentProps.onBlur = () => onControlBlur(name);
        componentProps.error = errors[name];
        componentProps.disabled = disabled;
      }

      const renderedComponent = (
        <ControlContainer
          key={name || index}
          withError={!!componentProps.error}
        >
          <ControlComponent {...componentProps} />
        </ControlContainer>
      );

      return [...renderedComponentsSoFar, renderedComponent];
    }, [])}
  </>
);

export default Controls;
