import { useRef, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "components/Button";
import useSize from "hooks/useSize";
import useTranslation from "hooks/useTranslation";
import getPainDiagramConfig from "./getPainDiagramConfig";
import createPainDiagram from "./createPainDiagram";
import styles from "./styles.module.scss";

const PainDiagram = ({
  painDiagramType,
  onChange,
  painDiagramData,
}) => {
  const t = useTranslation("universal.pain_diagram");
  const canvasRef = useRef();
  const containerRef = useRef();
  const [painDiagramPad, setPainDiagramPad] = useState(null);
  const painDiagramTypeConfig = getPainDiagramConfig(painDiagramType);
  const { width } = useSize(containerRef);
  const height = painDiagramTypeConfig.height * (width / painDiagramTypeConfig.width);

  const handleChange = () => {
    onChange(painDiagramPad.toDataURL("image/png"));
  };

  useEffect(() => {
    setPainDiagramPad(createPainDiagram(canvasRef.current));
  }, []);

  useEffect(() => {
    if (painDiagramPad) {
      if (painDiagramData) {
        painDiagramPad.fromDataURL(painDiagramData, { width, height });
      }

      painDiagramPad.removeEventListener("endStroke");
      painDiagramPad.addEventListener("endStroke", handleChange);
    }

    return () => {
      painDiagramPad?.removeEventListener("endStroke");
    };
  }, [painDiagramPad, width]);

  const showClearButton = useMemo(() => !!painDiagramData, [painDiagramData]);

  const handleClear = () => {
    painDiagramPad.clear();
    onChange(null);
  };

  return (
    <Box ref={containerRef}>
      <Box
        className={styles[painDiagramTypeConfig.className]}
        key="canvasContainer"
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <canvas
          height={height}
          ref={canvasRef}
          width={width}
        />
      </Box>
      {showClearButton && (
        <Box key="clearButtonContainer" sx={{ pt: 2, textAlign: "center" }}>
          <Button onClick={handleClear} variant="text">
            {t("clear")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PainDiagram;

PainDiagram.propTypes = {
  onChange: PropTypes.func,
  painDiagramData: PropTypes.string,
  painDiagramType: PropTypes.string.isRequired,
};

PainDiagram.defaultProps = {
  onChange: () => {},
  painDiagramData: null,
};
